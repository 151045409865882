<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.basicInfo')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12" class="display-2 font-weight-bold">
              PO# - {{ myObj.po }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-select
                :items="types"
                :label="$vuetify.lang.t('$vuetify.type')"
                :readonly="
                  (obj.status == 2 && !canEdit) || (obj.status == 3 && !canEdit)
                "
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-select
                v-model="myObj.currency"
                :rules="generalRule"
                :items="currencyOptions"
                :label="$vuetify.lang.t('$vuetify.currency')"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-select
                v-model="myObj.account_period"
                :rules="generalRule"
                :items="periodOptions"
                :label="$vuetify.lang.t('$vuetify.AccountPeriod')"
                :readonly="
                  (obj.status == 2 && !canEdit) || (obj.status == 3 && !canEdit)
                "
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pa-0 py-4">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.provideInfo") }}
              </span>
            </v-col>

            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-select
                :items="warehouseOptions"
                :rules="generalRule"
                v-model="myObj.warehouse_id"
                :label="$vuetify.lang.t('$vuetify.ArrivalWarehouse')"
                :readonly="
                  (obj.status == 2 && !canEdit) || (obj.status == 3 && !canEdit)
                "
              />
            </v-col>

            <v-col class="py-0" cols="10" lg="5" md="5" sm="5">
              <v-text-field
                :rules="generalRule"
                :label="$vuetify.lang.t('$vuetify.Supplier')"
                v-model="obj.company"
                readonly
              />
            </v-col>

            <span class="d-flex justify-center align-center">
              <v-btn
                elevation="0"
                fab
                color="primary"
                small
                @click="handleDialogSupplier"
                :dark="
                  canEdit == true || addAction == true || editAction == true
                "
                :disabled="
                  canEdit == false && addAction == false && editAction == false
                "
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </span>
          </v-row>

          <!-- Remark -->
          <v-row>
            <v-col cols="12">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.note") }}
              </span>
            </v-col>

            <v-col class="pb-0" cols="12" lg="12" md="6" sm="12">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.remarks')"
                v-model="myObj.note"
                outlined
                :disabled="
                  (obj.status == 2 && !canEdit) || (obj.status == 3 && !canEdit)
                "
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-file-input
                class="pa-0 ma-0 mt-0"
                :label="$vuetify.lang.t('$vuetify.annex')"
                prepend-inner-icon="mdi-file"
                prepend-icon=""
                @change="(item) => uploadFile(item)"
              />
            </v-col>

            <!-- List Files -->
            <v-col cols="12" class="pt-0" v-if="attachments.length > 0">
              <span class="display-2 font-weight-bold">
                List of Documents
              </span>

              <v-sheet
                elevation="4"
                class="my-4 py-4"
                height="250px"
                style="overflow-y: scroll"
              >
                <span
                  class="d-flex align-center justify-center"
                  v-for="(item, key) in attachments"
                  :key="key"
                >
                  <v-list-item
                    class="tile ml-4 mr-2 my-2"
                    :href="dataUrl + item"
                    target="_blank"
                  >
                    {{ item }}
                  </v-list-item>

                  <v-btn
                    color="red"
                    class="ml-2 mr-4"
                    outlined
                    @click="removeAttach(item)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </v-sheet>
            </v-col>
          </v-row>

          <!-- Product List -->
          <v-row class="pb-2">
            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="4"
              align-self="center"
              class="pa-0"
            >
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.goodsList") }}
              </span>
            </v-col>

            <v-spacer />

            <v-col
              class="d-flex justify-end align-center"
              cols="12"
              lg="4"
              md="4"
              sm="4"
              v-if="
                myObj.status != 2 && myObj.status != 3 && auditAction == false
              "
            >
              <v-btn
                class="ma-0"
                outlined
                color="primary"
                @click="handleDialogProduct"
              >
                {{ $vuetify.lang.t("$vuetify.goodsAdd") }}
              </v-btn>
            </v-col>

            <v-col cols="12">
              <PurchaseProductTable
                :head="selectedHeaders"
                :data="selectedProduct"
                :myObj="myObj"
                :addAction.sync="addAction"
                :editAction.sync="editAction"
                :auditAction.sync="auditAction"
                :abolishAction.sync="abolishAction"
                v-on:deleteitem="handleDeleteDialog"
                v-on:TotalPriceQty="TotalPriceQty"
              />

              <DialogDelete
                :dialogDelete.sync="dialogDelete"
                :deleteObj="deleteProductObj"
                @handleDelete="handleDeleteConfirm"
              />
            </v-col>
          </v-row>

          <v-row v-if="myObj.status == 2 || myObj.status == 3">
            <v-col cols="12">
              <template>
                <v-form
                  v-model="validLogistic"
                  ref="logisticForm"
                  lazy-validation
                >
                  <v-row class="pt-7 mt-5" style="border-top: 1px solid #ccc">
                    <v-col class="pl-5" cols="12" lg="12" md="12" sm="12">
                      <span>
                        {{
                          $vuetify.lang.t("$vuetify.ActualTotalPriceArrived")
                        }}: {{ ActualTotalPrice }}
                      </span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="pl-5" cols="12" lg="6" md="6" sm="6">
                      <v-text-field
                        style="color: black"
                        :label="$vuetify.lang.t('$vuetify.WaybillNumber')"
                        v-model="post_info.post_no"
                        :rules="generalRule"
                      />
                    </v-col>

                    <v-col class="pl-5" cols="12" lg="6" md="6" sm="6">
                      <v-text-field
                        style="color: black"
                        :label="$vuetify.lang.t('$vuetify.ShippingCompany')"
                        v-model="post_info.post_company"
                        :rules="generalRule"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12">
                      <v-btn
                        color="green darken-2"
                        style="width: 100%"
                        @click="logistics"
                        v-if="
                          permissionCheck('modify', 'purchaseReceive') &&
                          !canEdit
                        "
                      >
                        {{
                          $vuetify.lang.t(
                            "$vuetify.ImproveLogisticsInformation"
                          )
                        }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </template>
            </v-col>
          </v-row>

          <v-row v-if="this.abolishAction == true">
            <v-col cols="12">
              <template>
                <v-row class="py-4">
                  <v-col cols="12" class="pa-0 py-4">
                    <span class="display-2">
                      <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                      {{ $vuetify.lang.t("$vuetify.cancelReason") }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pa-0" cols="12">
                    <v-textarea
                      :label="$vuetify.lang.t('$vuetify.cancelReason')"
                      v-model="myObj.cancel_reason"
                      outlined
                      height="160"
                      :rules="generalRule"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>

          <v-row class="pa-3">
            <v-col class="py-0" cols="12" lg="3" md="3" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.taxRate')"
                type="number"
                suffix="%"
                v-model.number="myObj.tax"
                :readonly="!canEdit && !editAction && !addAction"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="3" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.totalQty')"
                readonly
                v-model.number="totalQty"
                :rules="generalRule"
                type="number"
              />
            </v-col>

            <v-col class="py-0" cols="12" lg="3" md="3" sm="4">
              <v-text-field
                type="number"
                v-model.number="totalPrice"
                readonly
                :rules="generalRule"
                :label="$vuetify.lang.t('$vuetify.TotalCost')"
                prefix="$"
              />
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              align-self="center"
              class="d-flex justify-end"
            >
              <v-btn
                color="primary"
                outlined
                elevation="0"
                @click="handleDialogDetail"
              >
                {{ $vuetify.lang.t("$vuetify.detail") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="py-8">
      <v-spacer />

      <v-btn
        color="red darken-2"
        outlined
        @click="cancelForm"
        v-if="this.obj.status != 3"
      >
        {{ $vuetify.lang.t("$vuetify.cancel") }}
        <span v-if="this.obj.status == 3">
          {{ $vuetify.lang.t("$vuetify.back") }}
        </span>
      </v-btn>

      <v-btn color="blue" @click="cancelForm" v-if="this.obj.status == 3">
        {{ $vuetify.lang.t("$vuetify.back") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="validate"
        v-if="this.editAction == true || this.addAction == true || canEdit"
      >
        {{ $vuetify.lang.t("$vuetify.confirm") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="validate"
        v-if="
          this.auditAction == true && permissionCheck('modify', 'purchaseAudit')
        "
      >
        {{ $vuetify.lang.t("$vuetify.approve") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="validate"
        v-if="
          this.abolishAction == true && permissionCheck('del', 'purchase_order')
        "
      >
        {{ $vuetify.lang.t("$vuetify.cancelOrder") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="handleDialogReceipt"
        v-if="
          obj.status == 2 &&
          permissionCheck('modify', 'purchaseReceive') &&
          !canEdit
        "
      >
        {{ $vuetify.lang.t("$vuetify.gathering") }}
      </v-btn>

      <v-btn
        color="blue"
        @click="canEdit = true"
        v-if="
          obj.status == 2 &&
          permissionCheck('modify', 'purchase_order') &&
          !canEdit
        "
      >
        {{ $vuetify.lang.t("$vuetify.modify") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import {
  purchaseUpser,
  purchaseInfo,
  checkPurchase,
  receivePurchase,
  cancelPurchase,
  postInfoPurchase,
  getTemp,
  pucharPdf,
  warehouseList,
} from "@/api/purchase";
import { listCurrency } from "@/api/system";
import { parmentList } from "@/api/financial";
import { profilePicUpload } from "@/api/company";
import serverConfig from "@/utils/serverConfig";

const newObj = () => {
  return {
    id: "",
    po: "",
    sale_po: "",
    sale_order_id: "",
    // purchase_user: store.state.user.userInfo.name,
    products: "",
    number: 0,
    price: 0,
    tax: "",
    contain_tax: true,
    currency: "USD",
    note: "",
    account_period: "",
    warehouse_id: "",
    supplier_id: "",
    attach: "",
    rate: "",
    price_detail: "", // {cash:online:other:} 总价明细
    purchase_type: "",
  };
};

const newDeleteProductObj = () => {
  return {
    id: "",
    type: 1,
    nick_name: "",
    number: 0,
    real_number: 0,
    ReturnNumber: 0,
    image: "",
    name: "",
    en_name: "",
    unit: "",
    other_code: "",
  };
};

const newPostInfo = () => {
  return {
    id: "",
    post_no: "",
    post_company: "",
  };
};

export default {
  props: {
    dialog: Boolean,
    supplierName: String,
    selectedProduct: Array,
    obj: Object,
    actions: Object,
    addAction: Boolean,
    editAction: Boolean,
    auditAction: Boolean,
    abolishAction: Boolean,
    real_price: Number,
    priceDetail: Object,
  },
  components: {
    DialogDelete: () => import("@/components/deleteDialog"),
    PurchaseProductTable: () =>
      import("@/components/purchase/purchaseProductTable"),
  },
  data() {
    return {
      dialogDelete: false,
      cancelData: false,
      valid: false,
      validLogistic: false,
      canEdit: false,
      deleteIndex: -1,
      actualTotalPrice: 0,
      actualPrice: 0,
      totalQty: 0,
      totalPrice: 0,
      dataUrl: serverConfig.img_url,
      deleteProductObj: newDeleteProductObj(),
      post_info: newPostInfo(),
      myObj: newObj(),
      deleteObj: newObj(),
      datas: [],
      attachments: [],
      selectedHeaders: [],
      warehouseOptions: [],
      currencyOptions: [],
      periodOptions: [],
      types: [{ text: "", value: "" }],
      arrivalWarehouse: [{ text: "A9 online", value: "A9 online" }],
      generalRule: [(v) => !!v || "this field is required"],
      periods: [
        { text: "Net 30 days", value: "Net 30 days" },
        { text: "Net 60 days", value: "Net 60 days" },
      ],
      headers1: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          sortable: false,
        },
        {
          value: "productDescription",
          width: "400px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.number"),
          value: "number",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.price"),
          value: "price",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "deleteProduct",
          sortable: false,
          align: "center",
        },
      ],
      headers2: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          sortable: false,
        },
        {
          value: "productDescription",
          width: "400px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.number"),
          value: "number",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.price"),
          value: "price",
        },
      ],
      headers3: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          sortable: false,
        },
        {
          value: "productDescription",
          width: "400px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.number"),
          value: "number",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.price"),
          value: "price",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.arrived"),
          value: "arrived",
          width: "220px",
        },
      ],
      headers4: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          sortable: false,
        },
        {
          value: "productDescription",
          width: "400px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.number"),
          value: "number",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.price"),
          value: "price",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.arrived"),
          value: "arrived",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "deleteProduct",
        },
      ],
    };
  },
  methods: {
    validate() {
      if (this.selectedProduct.length > 0) {
        this.myObj.products = this.selectedProduct;
      } else {
        this.$toast.info("Please Select Product");
        this.valid = false;
      }

      this.myObj.number = this.totalQty;
      this.myObj.price = this.totalPrice;

      if (this.canEdit == true) {
        this.$emit("update:editAction", true);
      }

      if (this.$refs.form.validate() == true) {
        this.myObj.attach = JSON.stringify(this.attachments);

        this.myObj.supplier_id = this.obj.supplier_id;

        this.$emit("handleData", this.myObj);
        this.$emit("update:real_price", this.actualPrice);
      } else {
        console.log("false");
      }

      this.canEdit = false;
    },
    cancelForm() {
      this.resetValidation();

      this.$emit("update:dialog", false);
      this.$emit("update:addAction", false);
      this.$emit("update:editAction", false);
      this.$emit("update:auditAction", false);
      this.$emit("update:abolishAction", false);
      this.$emit("update:supplierName", "");
      this.$emit("update:selectedProduct", []);
      this.$emit("update:obj", newObj());
      this.$emit("update:real_price", 0);

      this.canEdit = false;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    resetObj() {
      this.myObj.id = "";
      this.myObj.po = "";
      this.myObj.sale_po = "";
      this.myObj.sale_order_id = "";
      this.myObj.supplier_id = "";
      this.myObj.number = 0;
      this.myObj.price = 0;
      this.myObj.tax = 0;
      this.myObj.currency = "";
      this.myObj.rate = 0;
      this.myObj.warehouse_id = "";
      this.myObj.status = 0;
      this.myObj.products = [];
      this.myObj.account_period = "";
      this.myObj.note = "";
      this.myObj.supplier = "";
      this.myObj.warehouse = "";
      this.cancel_reason = "";
    },
    handleDialogProduct() {
      this.$emit("selectProduct");
      this.dialogProduct = true;
    },
    handleDialogDetail() {
      this.$emit("paymentDetail");
      this.dialogDetail = true;
    },
    handleDialogSupplier() {
      this.$emit("selectSupplier");
      this.dialogSupplier = true;
    },
    handleDeleteDialog(obj) {
      this.deleteProductObj = obj;
      this.deleteIndex = this.selectedProduct.indexOf(obj);
      this.dialogDelete = true;
    },
    handleDeleteConfirm() {
      if (this.deleteIndex > -1) {
        this.selectedProduct.splice(this.deleteIndex, 1);
        this.deleteIndex = -1;
      }
      this.dialogDelete = false;
      this.TotalPriceQty();
    },
    handleDialogReceipt() {
      this.$emit("ReceiptCRUD");
      this.dialogReceipt = true;
    },
    TotalPriceQty() {
      this.totalQty = 0;
      this.totalPrice = 0;

      this.selectedProduct.forEach((p) => {
        p.price == undefined ? (p.price = 0) : (p.price = p.price);
        p.number == undefined ? (p.number = 0) : (p.number = p.number);

        this.totalPrice += parseFloat(p.number) * parseFloat(p.price);

        this.totalQty += parseInt(p.number);
      });

      this.totalPrice = this.totalPrice.toFixed(2);
    },
    async getInfo() {
      if (this.obj.id) {
        await purchaseInfo(this.obj.id)
          .then((res) => {
            this.myObj = res.item;

            this.post_info.post_no = this.myObj.post_no;
            this.post_info.post_company = this.myObj.post_company;
            this.myObj.warehouse = this.myObj.warehouse_info.name;
            this.myObj.supplier = this.myObj.supplier_info.company;
            this.myObj.totalReceive = 0;
            this.myObj.totalReturn = 0;
            this.myObj.id = this.obj.id;

            this.obj.company = this.obj.supplier_info.company;

            this.$emit("handlePriceDetailData", this.myObj.price_detail);
          })
          .catch((err) => console.log("Purchase Info Error", err));
      }
    },
    logistics(obj) {
      if (this.$refs.logisticForm.validate() == true) {
        this.post_info.id = this.obj.id;
        postInfoPurchase(this.post_info).then((res) => {
          // this.reverseBack(res.error)
          this.$emit("update:dialog", false);
          this.$emit("getData");
        });
      }
    },
    getWarehouse() {
      warehouseList().then((data) => {
        var warehouses = [...data.items];

        for (var i = 0; i < warehouses.length; i++) {
          this.warehouseOptions.push({
            text: warehouses[i].name,
            value: warehouses[i].id,
          });
        }
      });
    },
    getCurrency() {
      listCurrency()
        .then((data) => {
          var currency = [...data.items];

          for (var i = 0; i < currency.length; i++) {
            this.currencyOptions.push({
              text: currency[i].name,
              value: currency[i].code,
            });
          }
        })
        .catch((err) => {
          console.log("List Currency Error", err);
        });
    },
    getPeriod() {
      parmentList().then((data) => {
        var periods = [...data.items];

        for (var i = 0; i < periods.length; i++) {
          this.periodOptions.push({
            text: periods[i].name,
            value: periods[i].name,
          });
        }
      });
    },
    removeAttach(item) {
      this.attachments.splice(this.attachments.indexOf(item), 1);
    },
    uploadFile(file) {
      const fileForm = new FormData();
      fileForm.append("file", file);

      profilePicUpload(fileForm)
        .then((res) => {
          this.attachments.push(res.data.md5);
        })
        .catch((err) => console.log("File Upload Error", err));
    },
  },
  computed: {
    ActualTotalPrice() {
      this.actualPrice = 0;
      for (var i = 0; i < this.selectedProduct.length; i++) {
        this.actualPrice +=
          parseFloat(this.selectedProduct[i].price) *
          parseFloat(this.selectedProduct[i].real_number);
      }
      return this.actualPrice;
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.myObj = this.obj;
        await this.getInfo();

        this.attachments = [];

        if (Array.isArray(this.myObj.attach)) {
          this.myObj.attach.forEach((p) => this.attachments.push(p.md5));
        }
      }

      if (this.obj.status == 1) {
        if (this.auditAction == true || this.abolishAction == true) {
          this.selectedHeaders = this.headers2;
        } else {
          this.selectedHeaders = this.headers1;
        }
      } else if (this.obj.status == 2 || this.obj.status == 3) {
        this.selectedHeaders = this.headers3;
      } else {
        this.selectedHeaders = this.headers1;
      }

      this.TotalPriceQty();
    },
    canEdit() {
      if (this.canEdit == true) {
        this.selectedHeaders = this.headers4;
      }
    },
    obj() {
      //this.myObj.supplier = this.obj.supplier;
      this.myObj.supplier_id = this.obj.supplier_id;
      // this.resetObj();
    },
  },
  mounted() {
    this.getWarehouse();
    this.getCurrency();
    this.getPeriod();
  },
};
</script>